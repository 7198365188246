import { figorrColors } from "../GlobalStyles/colors";
import appLogo from "../assets/figorr-small-logo.svg";
import { CompanyConfig } from "../interfaces/companyConfig.interface";

const figorrConfig: CompanyConfig = {
  appName: "Figorr Business App",
  appLogo,
  colors: figorrColors,
  companyName: "Figorr",
  hiddenFeaturesPath: ["/kitchen-orders", "/subscriptions"],
};

export default figorrConfig;
