import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { ModalBox } from "../../pages/settings/style";
import { Button } from "../button/Button";
import { CancelButtonComp } from "../button/CancelButton";
import { ReactNode } from "react";
import { ModalWrapper } from "./ModalWrapper";

const ConfirmAction = ({
  setConfirmSignout,
  doAction,
  action,
  actionText,
  actionBtnColor,
}: {
  setConfirmSignout: Function;
  doAction: Function;
  action: string;
  actionText: string | ReactNode;
  actionBtnColor?: string;
}) => {
  return (
    <>
      <ModalWrapper onClose={() => setConfirmSignout(false)}>
        <ModalBox position width="26rem" textMargin="0 0" className="settings-modal">
          <Flex justifyContent="flex-end">
            <CancelButtonComp handleClick={() => setConfirmSignout(false)} />
          </Flex>
          <Flex
            height="fit-content"
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Span color={Colors.grey} textAlign="center">
              <h3>{action}</h3>
            </Span>
            <Span color={Colors.grey} margin="0.625rem 0">
              {actionText}
            </Span>
          </Flex>

          <Flex justifyContent="flex-end" margin="1.5rem 0 0 0" gap="1rem">
            <Button
              width="30%"
              height="2.5rem"
              borderRadius="0.45rem"
              margin="0"
              color={Colors.grey}
              backgroundColor={"transparent"}
              label="Cancel"
              onClick={() => setConfirmSignout(false)}
            />
            <Button
              width="40%"
              height="2.5rem"
              borderRadius="0.45rem"
              margin="0"
              color={Colors.white}
              backgroundColor={actionBtnColor || Colors.red}
              label={action}
              onClick={() => {
                doAction();
                setConfirmSignout(false);
              }}
            />
          </Flex>
        </ModalBox>
      </ModalWrapper>
    </>
  );
};

export default ConfirmAction;
