import { IPendingShop, IShop } from "./../../interfaces/shop.interface";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface IIntial {
  list: any[];
  invitedShops: IPendingShop[];
  currentShop: IPendingShop;
  newUserData: string;
  syncTableUpdateCount: {
    [key: string]: number;
  };
}

const initialState: IIntial = {
  list: [],
  invitedShops: [],
  currentShop: {},
  newUserData: "",
  syncTableUpdateCount: {},
};

const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    setShops (state, { payload }) {
      if (payload) {
        state.list = [...payload];
      }
    },

    setInvitedShops (state, { payload }) {
      if (payload) {
        state.invitedShops = payload;
      }
    },

    setNewUserData (state, { payload }) {
      if (payload) {
        state.newUserData = payload;
      }
    },

    setCurrentShop (state, { payload }: { payload: IShop }) {
      if (!payload) {
        state.currentShop = {};
        return;
      }

      state.currentShop = payload;
    },

    increaseSyncCount (state, { payload }) {
      const tableNames = payload;
      const syncTableUpdateCount = { ...state.syncTableUpdateCount };
      tableNames.forEach((tableName: string) => {
        syncTableUpdateCount[tableName] = (syncTableUpdateCount[tableName] ?? 0) + 1;
      });
      state.syncTableUpdateCount = syncTableUpdateCount;
    },
  },
});

export const getShops = (state: RootState) => state.shops.list;
export const getNewUserData = (state: RootState) => state.shops.newUserData;
export const getInvitedShops = (state: RootState) => state.shops.invitedShops;
export default shopsSlice.reducer;
export const {
  setShops, setCurrentShop, setInvitedShops,
  increaseSyncCount, setNewUserData
} = shopsSlice.actions;
export const getCurrentShop = (state: RootState) => state.shops.currentShop;
