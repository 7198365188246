import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";
import ResetImage from "../../assets/resetPwd-bg.png";
import { dynamicProps } from "../../config";
import { Company } from "../../utils/constants";

const { primaryColor } = Colors;

export const Container = styled.div`
  background: #f4f6f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: ${dynamicProps({ [Company.TIMART]: "column", [Company.FIGORR]: "row" })};
  gap: ${dynamicProps({ [Company.TIMART]: "unset", [Company.FIGORR]: "29.2em" })};
  align-items: center;
  padding: 1.875rem;
  background: ${dynamicProps({
    [Company.TIMART]: `url(${ResetImage}), #f4f6f9`,
    [Company.FIGORR]: primaryColor,
  })};
  background-position: bottom left;
  background-size: 30%;
  background-repeat: no-repeat;
  @media screen and (max-width: 434px) {
    padding: 0 0.8rem;
    flex-direction: column;
  }
`;
export const Back = styled.div`
  display: flex;
  background: #f4f6f9;
  height: 2.1875rem;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  border: 1px solid #607087;
  border-radius: 0.5em;
  align-self: start;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
