import companyConfig from "../config";
import { timartColors } from "./colors";

export const Colors: { [K in keyof typeof timartColors]: (typeof timartColors)[K] } =
  companyConfig.colors;

export const fontSizes = {
  large: "1.125rem",
  base: "1rem",
  small: "0.75rem",
  extraSmall: "0.56rem",
  xxSmall: "0.3rem",
};

export const FontFamily = {
  primaryFont: "Manrope",
  secondaryFont: "Helvetica",
};

export const BoxShadows = {
  primaryBoxShadow: "4px 4px 1.875rem rgba(35, 54, 79, 0.1)",
  SmallerBoxShadow: "4px 4px 1.25rem rgba(24, 88, 177, 0.2)",
  cardBoxShadow: "4px 4px 1.875rem rgba(23, 46, 78, 0.1)",
};
