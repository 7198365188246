import { timartColors } from "../GlobalStyles/colors";
import appLogo from "../assets/timart-logo.png";
import { CompanyConfig } from "../interfaces/companyConfig.interface";

const timartConfig: CompanyConfig = {
  appName: "Timart Business App",
  appLogo,
  colors: timartColors,
  companyName: "Timart",
};

export default timartConfig;
