import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { CancelButton } from "../../pages/sales/style";
import { ModalBox } from "../expenseModal/style";
import { ModalWrapper } from "./ModalWrapper";

const EdmartSubModal = (props: { setShowModal: Function }) => {
  return (
    <ModalWrapper onClose={() => props.setShowModal()}>
      <ModalBox>
        <Flex justifyContent="flex-end">
          <CancelButton hover onClick={() => props.setShowModal()}>
            X
          </CancelButton>
        </Flex>
        <Flex height="fit-content" direction="column" justifyContent="center">
          <Flex height="15rem" justifyContent="center">
            <svg
              fill="#0d0d0d"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 33.834 33.834"
              stroke="#0d0d0d"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <path d="M32.253,29.334v4.5H1.581v-4.501c0-2.125,1.832-4.741,4.07-5.804l4.98-2.366l3.457,7.204l1.77-4.799 c0.349,0.066,0.695,0.154,1.059,0.154s0.709-0.088,1.059-0.154l1.68,4.563l3.389-7.048l5.141,2.445 C30.421,24.591,32.253,27.207,32.253,29.334z M6.105,13.562v-3.25c0-0.551,0.287-1.034,0.72-1.312c0.581-5.058,4.883-9,10.094-9 s9.514,3.942,10.096,9c0.432,0.278,0.719,0.761,0.719,1.312v3.25c0,0.863-0.699,1.563-1.563,1.563s-1.563-0.7-1.563-1.563v-0.683 c-0.846,4.255-3.961,8.205-7.688,8.205c-3.727,0-6.842-3.95-7.688-8.205v0.683c0,0.7-0.465,1.286-1.1,1.485 c0.622,2.117,2.002,3.946,3.908,5.146c0.352-0.116,0.796-0.094,1.227,0.13c0.692,0.36,1.045,1.06,0.783,1.56 c-0.261,0.5-1.033,0.612-1.729,0.251c-0.508-0.265-0.83-0.71-0.864-1.126c-2.183-1.396-3.731-3.533-4.37-5.998 C6.513,14.78,6.105,14.22,6.105,13.562z M7.89,8.635c0.047,0.003,0.092,0.004,0.137,0.021C8.14,8.698,8.222,8.779,8.279,8.874 c0.339,0.144,0.609,0.407,0.775,0.733C9.515,5.286,12.855,3,16.917,3c4.062,0,7.402,2.286,7.863,6.607 c0.229-0.449,0.664-0.77,1.185-0.837c-0.676-4.393-4.47-7.771-9.048-7.771C12.386,1,8.622,4.309,7.89,8.635z"></path>{" "}
                </g>{" "}
              </g>
            </svg>
          </Flex>
          <Span color={Colors.grey} textAlign="center" margin="0.625rem 0">
            <h3>Call Edmart</h3>
            <p>
              <b>Phone: </b> +234 8054415588
            </p>
          </Span>
        </Flex>
      </ModalBox>
    </ModalWrapper>
  );
};

export default EdmartSubModal;
