import { ReactNode } from "react";
import { ModalContainer } from "../../pages/settings/style";
import { Flex } from "../../GlobalStyles/CustomizableGlobal.style";

type Props = {
  onClose: (T: boolean) => void;
  children: ReactNode | ReactNode[];
  color?: string;
};
export const ModalWrapper = ({ onClose, children, color }: Props) => {
  return (
    <ModalContainer color={color} onClick={() => onClose(false)}>
      <Flex width="100%" justifyContent="center">
        <div style={{ width: "fit-content" }} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </Flex>
    </ModalContainer>
  );
};
