import { FunctionComponent } from "react";
import { Card } from "./style";
import CompanyConfig, { isCompany } from "../../config";
import isMobileOrTablet from "../../utils/isMobileOrTablet";

interface Props {
  children: JSX.Element | JSX.Element[];
  width: string;
  showLogo?: boolean;
}
const AuthCard: FunctionComponent<Props> = ({ children, width, showLogo = true }) => {
  const mobileORDesktop = isMobileOrTablet();
  const shouldShowLogo = !isCompany("FIGORR") || (isCompany("FIGORR") && mobileORDesktop);
  return (
    <Card width={width}>
      {shouldShowLogo && <img src={CompanyConfig.appLogo} alt="logo" width="50px" />}
      {children}
    </Card>
  );
};

export default AuthCard;
