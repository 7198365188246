import cancelIcon from "../../assets/cancel.svg";
import { CancelButton } from "../../pages/sales/style";

type Props = {
  handleClick: () => void;
};

export const CancelButtonComp = ({ handleClick }: Props) => {
  return (
    <CancelButton style={{ padding: "0.5rem" }} hover onClick={handleClick}>
      <img src={cancelIcon} alt="" />
    </CancelButton>
  );
};
