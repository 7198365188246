/* eslint-disable no-debugger */
import { useLazyQuery } from "@apollo/client";
import { Children, FunctionComponent, ReactNode, useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, useCurrentShop } from "../../app/hooks";
import { getShops, increaseSyncCount, setCurrentShop, setShops } from "../../app/slices/shops";
import { toggleSnackbarOpen } from "../../app/slices/snacbar";
import { getSyncStatus } from "../../app/slices/syncStatus";
import { getCurrentUser } from "../../app/slices/userInfo";
import { syncTotalTableCount } from "../../helper/comparisons";
import { clientLocalSocket } from "../../services/clientLocalSocket";
import { GET_ALL_SHOPS } from "../../schema/shops.schema";
import { SYNC_START, SYNC_STATUS } from "../../utils/constants";
import Sidebar from "../sidebar/sidebar";
import { Container, LeftContainer } from "./style";
import { isDesktop } from "../../utils/helper.utils";
import { getSessions } from "../../app/slices/session";

interface IShop {
  shopId: string;
  shopName: string;
  shopAddress: string;
}

export interface SyncLogProps {
  type: string;
  date: string;
  log: string;
}

export interface syncStatusProps {
  count?: number;
  total?: number;
  running: boolean;
  lastSyncDate?: number;
  percentage?: number;
  error?: string | null;
  logs?: SyncLogProps[];
  progress?: number;
  totalRecordsToPush?: number;
  more?: any;
}

const DashboardWrapper: FunctionComponent = ({ children }: { children?: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [syncStatus, setSyncStatus] = useState<syncStatusProps>({
    running: false,
  });
  const currentShop = useCurrentShop();
  const localCurrentShop = JSON.parse(localStorage.getItem("currentShop") || "{}");
  const shopId = currentShop?.shopId;

  const shopList = useAppSelector(getShops);
  const currentUser = useAppSelector(getCurrentUser);
  const navigate = useNavigate();
  const sessions = useAppSelector(getSessions);

  const [getAllShops, { data }] = useLazyQuery<{ getUsersShops: [IShop] }>(GET_ALL_SHOPS, {
    fetchPolicy: "network-only",
    onCompleted: (shopData) => {
      if (Number(shopData?.getUsersShops.length) > Number(shopList.length)) {
        dispatch(setShops(shopData?.getUsersShops));
      }
    },
    onError: (error) => {
      dispatch(
        toggleSnackbarOpen({
          message: error?.message || error?.graphQLErrors[0]?.message,
          color: "DANGER",
        })
      );
    },
  });

  const startSync = async () => {
    if (syncStatus.running) {
      alert("Still synchronizing. Please wait...");
      return;
    }
    clientLocalSocket.emit(SYNC_START, { shopId });
  };

  useEffect(() => {
    clientLocalSocket.emit(SYNC_START, { shopId });

    if (Object.keys(currentShop)?.length > 0) return;
    dispatch(setCurrentShop(localCurrentShop));
    if (!shopList.length && !shopId && !localCurrentShop && sessions.session.token) {
      navigate("/create-business");
    }
  }, [shopId]);

  const syncTableUpdateCount = useAppSelector((state) =>
    syncTotalTableCount(state.shops.syncTableUpdateCount, ["Shops"])
  );

  useEffect(() => {
    dispatch(setShops(data?.getUsersShops));
  }, [data]);

  useEffect(() => {
    if (currentUser?.userId) {
      getAllShops();
    }
  }, [currentUser?.userId, syncTableUpdateCount]);

  const handleConnect = useCallback(() => {
    clientLocalSocket.emit(SYNC_START, { shopId });
  }, []);

  const handleStatusChange = useCallback(
    (response: syncStatusProps) => {
      setSyncStatus(response);
      dispatch(getSyncStatus(response.running));
      if (response.more?.type === "tables-updated") {
        dispatch(increaseSyncCount(response.more.tableNames));
      }
    },
    [setSyncStatus]
  );

  useEffect(() => {
    clientLocalSocket.on("connect", handleConnect);
    clientLocalSocket.on(SYNC_STATUS, handleStatusChange);

    return () => {
      clientLocalSocket.removeListener("connect", handleConnect);
      clientLocalSocket.removeListener(SYNC_STATUS, handleStatusChange);
    };
  }, [shopId, handleStatusChange, handleConnect]);

  return (
    <Container>
      <Sidebar startSync={startSync} />
      {children ? (
        <>{children}</>
      ) : (
        <LeftContainer>
          <Outlet />
        </LeftContainer>
      )}
    </Container>
  );
};

export default DashboardWrapper;
