/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import { io as clientIo } from "socket.io-client";
import { getItem, getItemAsObject } from "../utils/localStorage.utils";
import { getServerUrl, isDesktop } from "../utils/helper.utils";
import { clientLocalSocket } from "./clientLocalSocket";
import { SYNC_START, SYNC_UPDATE_SHOP, SYNC_REGISTER_SHOP, SYNC_STATUS } from "../utils/constants";

const SERVER_URL: any = getServerUrl();
const deviceUUID = getItem("DEVICE_UUID");
const currentShop = getItemAsObject("currentShop");

export let clientServerSocket: any = {
  emit: () => {},
  on: () => {},
  disconnect: () => {},
};

export const initSocket = () => {
  if (!isDesktop()) return;

  clientServerSocket.disconnect();

  const sessions = getItemAsObject("session");
  const token = sessions.token;
  const parsedToken = token;

  clientServerSocket = clientIo(SERVER_URL, {
    query: {
      token: parsedToken,
    },
    reconnection: true,
  });

  clientServerSocket.on("connect", () => {
    registerOrUpdateShopIdsOnServer();
  });

  clientServerSocket.on(SYNC_UPDATE_SHOP, ({ shopId }: { shopId: string }) => {
    // TODO: Implement call for update for web
    if (!isDesktop()) return;
    clientLocalSocket.emit(SYNC_START, { shopId });
  });
};

const registerOrUpdateShopIdsOnServer = () => {
  if (!clientServerSocket || !deviceUUID?.length) {
    return;
  }

  syncRegisterShop(currentShop?.shopId);
};

export const syncRegisterShop = (shopId: string) => {
  if (!clientServerSocket || !deviceUUID?.length) {
    return;
  }

  clientServerSocket.emit(
    SYNC_REGISTER_SHOP,
    {
      shopIds: [shopId],
      deviceUUID,
    },
    (...args: any[]) => {
      if (!args[0].success) return initSocket();
      clientLocalSocket.emit(SYNC_START, { shopId: currentShop?.shopId });
    }
  );

  clientLocalSocket.emit(SYNC_STATUS, { shopId: currentShop.shopId });
};

const startSync = () => {
  if (!currentShop.shopId) {
    return;
  }
  clientLocalSocket.emit(SYNC_START, { shopId: currentShop.shopId });
};

// Start Sync every 30 seconds
setInterval(startSync, 30 * 1000);
