import { figorrColors } from "../GlobalStyles/colors";
import appLogo from "../assets/edmart-logo.jpg";
import { CompanyConfig } from "../interfaces/companyConfig.interface";

const edmartConfig: CompanyConfig = {
  appName: "Edmart Business App",
  appLogo,
  colors: figorrColors,
  companyName: "Edmart",
  hiddenFeaturesPath: ["/kitchen-orders", "/subscriptions"],
};

export default edmartConfig;
