import styled from "styled-components";
import { Colors, fontSizes } from "../../GlobalStyles/theme";
import LockImage from "../../assets/lock-bg.png";
import { Company } from "../../utils/constants";
import { dynamicProps } from "../../config";

const { primaryColor, secondaryColor, white } = Colors;

interface SidebarProps {
  backgroundColor?: string;
  textColor?: string;
  show?: boolean;
  indicator?: string;
  access?: boolean;
  fontWeight?: number;
  isTimart?: boolean;
}

export const SyncWrapper = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 0.3125rem 0;

  p {
    font-size: ${fontSizes.small};
    color: ${dynamicProps({ [Company.TIMART]: white, [Company.FIGORR]: secondaryColor })};
    text-align: center;
    margin-bottom: 4px;
  }

  div {
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: ${fontSizes.extraSmall};
    color: ${dynamicProps({ [Company.TIMART]: primaryColor, [Company.FIGORR]: secondaryColor })};
  }
`;

export const SyncButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  width: 13px;
  height: 13px;
  align-self: center;
  align-items: center;
`;

export const LinkContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 35%;
  max-height: 35%;
  height: 35%;
  padding-right: 0.3125rem;
  overflow-y: scroll;

  scrollbar-width: 3px;
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${primaryColor};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${Colors.faintBlue};
  }

  #upgrade {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    width: 100%;
    height: 2.5rem;
    background-color: #f2c94c;
    background-image: -webkit-linear-gradient(
      -30deg,
      ${dynamicProps({ TIMART: "#f2c94c" }, "#0F8DE9")} 0%,
      ${dynamicProps(
          {
            TIMART: "#f7917d",
          },
          "#7793D4"
        )}
        100%
    );
    background-image: linear-gradient(
      -30deg,
      ${dynamicProps(
          {
            TIMART: "#f2c94c",
          },
          "#0F8DE9"
        )}
        0%,
      ${dynamicProps({ TIMART: "#f7917d" }, "#7793D4")} 100%
    );

    border-radius: 0.625rem;
    padding: 0.3125rem 0.625rem;
    border: none;
    font-size: ${fontSizes.base};
    color: white;
    font-family: sans-serif;
    margin-top: 1rem;
    cursor: pointer;

    :hover {
      background-image: -webkit-linear-gradient(
        -80deg,
        ${dynamicProps({ TIMART: "#f7917d" }, "#7793D4")} 0%,
        ${dynamicProps({ TIMART: "#f7917d" }, "#0F8DE9")} 100%
      );
      background-image: linear-gradient(
        -80deg,
        ${dynamicProps({ TIMART: "#f7917d" }, "#7793D4")} 0%,
        ${dynamicProps({ TIMART: "#f7917d" }, "#0F8DE9")} 100%
      );
    }

    img {
      width: 1.25rem;
    }
  }
`;

export const Container = styled.div`
  width: 12.5rem;
  min-width: 12.5rem;
  padding: 0.625rem;
  // border-radius: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 1.25rem 0px;
  height: calc(100vh);
  min-height: (100vh);
  overflow-y: scroll;
  z-index: 99;
  background: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;

  .logout {
    position: absolute;
    bottom: 3.5rem;
    width: 90%;
  }
  .end-shift {
    position: absolute;
    bottom: 1rem;
    width: 90%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 800px) {
    position: fixed;
    transition: 0.3s linear;
    display: ${(props: SidebarProps) => (props.show ? "flex" : "none")};
    left: ${(props: SidebarProps) => (props.show ? "0px" : "-12.5rem")};
    overflow-y: scroll;
    border-radius: 0px;
    height: 100vh;
    min-height: calc(100vh - 3.125rem);
    :hover {
      padding: 0.625rem;
    }
  }
`;
export const Logo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  height: auto;
  min-height: auto;
  padding-bottom: 0.3125rem;
  img {
    height: 2.4375rem;
    float: left;
  }
`;

export const LinkButton = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 0 4px;
  height: 1.875rem;
  min-height: 1.875rem;
  width: calc(100% + 1.25rem);
  font-size: 0.8125rem;
  margin: 0.3125rem auto;
  gap: 0.625rem;
  border-radius: 0.5rem;
  font-weight: ${(props: SidebarProps) => props.fontWeight || "unset"};
  color: ${(props: SidebarProps) => props.textColor || "#607087"};
  background: ${(props: SidebarProps) => props.backgroundColor || "transparent"};
  opacity: ${({ access }) => (access ? 1 : 0.5)};

  :hover {
    background: ${(props: SidebarProps) => props.backgroundColor || "#130F2624"};
  }
  #icon {
    margin-left: 0.625rem;
    min-height: 0.9375rem;
    width: 0.9375rem;
  }

  span {
    font-size: 0.8125rem;
  }
  #lock {
    display: ${({ access }) => (access ? "none" : "flex")};
    position: absolute;
    right: 1.875rem;
  }
`;

export const ExclusivesButton = styled.button<SidebarProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  height: 2.1875rem;
  margin: 0.5em 0 0.5rem 0.3rem;
  border-bottom: 1px solid #f0f3f8;
  min-height: 2.1875rem;
  width: calc(100% - 0.625rem);
  font-size: 0.6875rem;
  border-radius: 0.75rem;
  background-color: transparent;
  color: ${(props: SidebarProps) => props.textColor || "#607087"};
  opacity: ${({ access }) => (access ? 1 : 0.5)};

  .indicator {
    min-height: 0.4375rem;
    min-width: 0.4375rem;
    height: 0.4375rem;
    width: 0.4375rem;
    margin-right: 0.625rem;
    border-radius: 50%;
    background: ${({ indicator }) => indicator || "transparent"};
  }

  #chev {
    width: 0.3125rem;
  }
  #lock {
    position: absolute;
    right: 0.625rem;
    display: ${({ access }) => (access ? "none" : "flex")};
    width: 1rem;
  }
`;
export const LogoutBtn = styled.button`
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #f65151;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.8125rem;
  img {
    margin-bottom: 3px;
    margin-inline: auto;
  }
`;

export const AccountLockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: url(${LockImage}), #f4f6f9;
  background-position: bottom right;
  background-size: 70%;
  background-repeat: no-repeat;
`;

export const LockSubContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 2.5rem;
  border-radius: 1rem;
  background: white;
  display: flex;
  flex-direction: column;
`;

export const UpgradeContainer = styled.div`
  background-color: #f0f0f0;
  padding: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 0.625rem;
  }

  li {
    line-height: 20px;
  }
`;

export const UpgradeTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.625rem;
`;

export const UpgradeMessage = styled.p`
  font-size: ${fontSizes.base};
  color: #000;
  margin-block: 0.75rem;
`;

export const UpgradeButton = styled.button<{
  backgroundColor?: string;
  hoverColor?: string;
  width?: string;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0 0.8rem;
  width: ${({ width }) => width ?? "65%"};
  height: 2.5rem;
  background-color: #f2c94c;
  border-radius: 0.5rem;
  border: none;
  color: white;
  cursor: pointer;
  background: linear-gradient(
    90.95deg,
    #1976d2 -109.46%,
    #219653 -3.09%,
    #d186ff -2.41%,
    #e47d05 149.36%
  );
  overflow: hidden;

  @keyframes hoverAnimation {
    0% {
      opacity: 0;
      top: -110%;
      left: -210%;
    }
    100% {
      opacity: 1;
      top: -30%;
      left: 35%;
    }
  }

  :after {
    animation: hoverAnimation 0.9s infinite;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 90%;
    height: 0.7rem;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const MenuBox = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 0.5rem;
  background-color: #f2f3f8;
  color: ${Colors.primaryColor};
  gap: 10px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const StyledHamburger = styled.div`
  .hamburger {
    padding: 2px 2px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }
  .hamburger:hover {
    opacity: 0.7;
  }
  .hamburger.is-active:hover {
    opacity: 0.7;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::after,
  .hamburger.is-active .hamburger-inner::before {
    background-color: #fff;
  }
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::after,
  .hamburger-inner::before {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--emphatic {
    overflow: hidden;
  }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 125ms 175ms ease-in;
  }
  .hamburger--emphatic .hamburger-inner::before {
    left: 0;
    transition: transform 125ms cubic-bezier(0.6, 0.04, 0.98, 0.335), top 50ms 125ms linear,
      left 125ms 175ms ease-in;
  }
  .hamburger--emphatic .hamburger-inner::after {
    top: 10px;
    right: 0;
    transition: transform 125ms cubic-bezier(0.6, 0.04, 0.98, 0.335), top 50ms 125ms linear,
      right 125ms 175ms ease-in;
  }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
  }
  .hamburger--emphatic.is-active .hamburger-inner::before {
    left: -80px;
    top: -80px;
    transform: translate3d(80px, 80px, 0) rotate(45deg);
    transition: left 125ms ease-out, top 50ms 125ms linear,
      transform 125ms 175ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .hamburger--emphatic.is-active .hamburger-inner::after {
    right: -80px;
    top: -80px;
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right 125ms ease-out, top 50ms 125ms linear,
      transform 125ms 175ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .hamburger--emphatic-r {
    overflow: hidden;
  }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 125ms 175ms ease-in;
  }
  .hamburger--emphatic-r .hamburger-inner::before {
    left: 0;
    transition: transform 125ms cubic-bezier(0.6, 0.04, 0.98, 0.335), top 50ms 125ms linear,
      left 125ms 175ms ease-in;
  }
  .hamburger--emphatic-r .hamburger-inner::after {
    top: 10px;
    right: 0;
    transition: transform 125ms cubic-bezier(0.6, 0.04, 0.98, 0.335), top 50ms 125ms linear,
      right 125ms 175ms ease-in;
  }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
  }
  .hamburger--emphatic-r.is-active .hamburger-inner::before {
    left: -80px;
    top: 80px;
    transform: translate3d(80px, -80px, 0) rotate(-45deg);
    transition: left 125ms ease-out, top 50ms 125ms linear,
      transform 125ms 175ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .hamburger--emphatic-r.is-active .hamburger-inner::after {
    right: -80px;
    top: 80px;
    transform: translate3d(-80px, -80px, 0) rotate(45deg);
    transition: right 125ms ease-out, top 50ms 125ms linear,
      transform 125ms 175ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;
