import { FC, ReactNode, Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import PackageStatusChecker from "../pages/subscriptions/util/PackageStatusChecker";
import { Flex } from "../GlobalStyles/CustomizableGlobal.style";
import Loader from "../components/loader";
import DashboardWrapper from "../components/dashboard-wrapper/dashboard-wrapper";

interface AuthCheckerProps {
  children: ReactNode | ReactNode[];
}

const AuthChecker: FC<AuthCheckerProps> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const subscriptions = useAppSelector((state) => state.subscriptions);
  const userSubscriptions = subscriptions?.subscriptions || [];

  const session = localStorage.getItem("session");
  const token = session ? JSON.parse(session)?.token : "";

  const isPublicRoute = (path: string) => {
    const publicRoutes = [
      "/",
      "/register",
      "/password-change",
      "/login",
      "/create-business",
      "/select-shop",
      "/recover-password",
    ];

    return path === "/" || publicRoutes.includes(path) || path.startsWith("/login");
  };
  const isLoggedin = !!token;

  useEffect(() => {
    if (!isLoggedin && !isPublicRoute(pathname)) {
      navigate("/login");
    }
  }, [pathname, isLoggedin]);

  return (
    <>
      {isLoggedin || isPublicRoute(pathname) ? (
        <>
          {userSubscriptions.length < 1 ? (
            <>
              {isPublicRoute(pathname) ? (
                <>
                  <Suspense
                    fallback={
                      <Flex
                        direction="column"
                        width="100%"
                        padding="0 1rem"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Loader noBg />
                      </Flex>
                    }
                  >
                    {children}
                  </Suspense>
                </>
              ) : (
                <Suspense
                  fallback={
                    <DashboardWrapper>
                      <Flex>
                        <Loader noBg />
                      </Flex>
                    </DashboardWrapper>
                  }
                >
                  <>{children}</>
                </Suspense>
              )}
            </>
          ) : (
            <PackageStatusChecker userPackage={userSubscriptions[0]}>
              <Suspense
                fallback={
                  <DashboardWrapper>
                    <Flex>
                      <Loader noBg />
                    </Flex>
                  </DashboardWrapper>
                }
              >
                <>{children}</>
              </Suspense>
            </PackageStatusChecker>
          )}
        </>
      ) : null}
    </>
  );
};

export default AuthChecker;
