import timartConfig from "./timart.config";
import figorrConfig from "./figorr.config";
import edmartConfig from "./edmart.config";
import { Company } from "../utils/constants";
import { getCompanyName } from "../utils/companySwitch.utils";
import { CompanyConfig } from "../interfaces/companyConfig.interface";

export const companyConfig = {
  [Company.TIMART]: timartConfig,
  [Company.FIGORR]: figorrConfig,
  [Company.EDMART]: edmartConfig,
};

const config = companyConfig[getCompanyName()];

const companyConfigProxy = new Proxy<CompanyConfig>(config, {
  get: function (target: CompanyConfig, key: string) {
    return target[key as keyof CompanyConfig];
  },
});

type CompanyKeys = keyof typeof Company;

export const dynamicProps = (props: Partial<typeof Company>, defaultValue: any = null) => {
  return (
    props[getCompanyName() as CompanyKeys] || defaultValue || props[Company.TIMART as CompanyKeys]
  );
};

export const showOfflineOrder = !config.hiddenFeaturesPath?.includes("/kitchen-orders");
export const isTimart = getCompanyName() === Company.TIMART;
export const isCompany = (companyName: keyof typeof Company) => getCompanyName() === companyName;

export default companyConfigProxy;
