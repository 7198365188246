// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent as logAnalyticEvent } from "firebase/analytics";
import * as firebase from "firebase/app";
import { isDesktop } from "../utils/helper.utils";
import { isStaging } from "../utils/constants";

function camelToSnake(camelCaseString: string) {
  // Use regex to match camelCase pattern and replace with snake_case
  return camelCaseString.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

class FirebaseAnalytics {
  private userId?: string;
  private shopId?: string;
  private analyticsInstance: any;

  constructor(firebaseConfig: any) {
    if (firebaseConfig.apiKey) {
      const initializeFirebase = firebase.initializeApp(firebaseConfig);
      this.analyticsInstance = getAnalytics(initializeFirebase);
    }

    !this.analyticsInstance && console.error("Firebase analytics not initialized");
  }

  // TODO: call this function when the user is loaded
  setUserId(userId: string) {
    this.userId = userId;
  }

  // TODO: call this function when shop is loaded or switched
  setShopId(shopId: string) {
    this.shopId = shopId;
  }

  private prepareEventParams = (eventParams: Record<string, string>) => {
    eventParams = { ...eventParams };

    if (this.userId) {
      eventParams.userId = this.userId;
    }

    if (this.shopId) {
      eventParams.shopId = this.shopId;
    }

    eventParams.timestamp = new Date().toISOString();
    // TODO: add app version
    // eventParams.appVersion = process.env.REACT_APP_VERSION;
    eventParams.platform = isDesktop() ? "desktop" : "web";

    return eventParams;
  };

  track(eventName: string, eventParams: Record<string, string> = {}) {
    return this.logEvent(eventName, eventParams);
  }

  trackCrashEvent() {
    throw new Error("Not implemented yet");
  }

  private logEvent(eventName: string, eventParams: Record<string, string> = {}) {
    if (isStaging) {
      // disable logging on stagging
      return;
    }

    if (!this.analyticsInstance) {
      return;
    }

    eventParams = this.prepareEventParams(eventParams);

    return logAnalyticEvent(this.analyticsInstance, camelToSnake(eventName), eventParams);
  }
}

export default new FirebaseAnalytics({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
