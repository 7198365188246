export const timartColors = {
  secondaryColor: "#E47D05",
  lightPrimary: "#FFF6EA",
  primaryColor: "#19113D",
  primaryGrey: "#4F4F4F",
  body: "#2F2F2F",
  white: "#FFFFFF",
  black: "#000000",
  transparentBlack: "#00000014",
  blackLight: "#607087",
  lightBlue: "#8196B3",
  darkBlue: "#2B3C54",
  backgroundColor: "#E5E5E5",
  lightGrey: "#828282",
  inputBorderColor: "#C7C7C7",
  green: "#219653",
  placeholderColor: "#B9B9B9",
  emptyStarColor: "#C4C4C4",
  overlayColor: "#607087b3",
  inActiveTab: "#F5F5F5",
  grey4: "#BDBDBD",
  buttonHover: "#b58336",
  onlineGreen: "#6CFF7C",
  red: "#F65151",
  offRed: "#ff5050",
  lightRed: "#FCE9E9",
  lightOrange: "#FBEEDB",
  lightBg: "#F6F8FB",
  hoverPrimary: "#FBF6F0",
  darkGreen: "#219653",
  blue: "#1976D2",
  faintBlue: "#E6F1FC",
  lightGreen: "#DBF9E8",
  skyBlue: "#E4EBF4",
  line: "#E4EBF4",
  offWhite: "#F3F7FC",
  senderBubble: "#8196B2",
  receiverBubble: "#DAE2EC",
  blackishBlue: "#130F26",
  tabBg: "#ECEFF4",
  grey: "#9EA8B7",
  lightSecondaryColor: "#FFF6EA",
  borderGreyColor: "#F0F3F8",
  deepSkyBlue: "#E0EAFF",
  greyBlue: "#213E7A",
  danger: "#F65151",
};

export const figorrColors = {
  primaryColor: "#24437D",
  lightPrimary: "#E3ECFF",
  secondaryColor: "#00A551",
  primaryGrey: "#4F4F4F",
  body: "#2F2F2F",
  white: "#FFFFFF",
  black: "#000000",
  transparentBlack: "#00000014",
  blackLight: "#607087",
  lightBlue: "#8196B3",
  darkBlue: "#2B3C54",
  backgroundColor: "#E5E5E5",
  lightGrey: "#828282",
  inputBorderColor: "#C7C7C7",
  green: "#219653",
  placeholderColor: "#B9B9B9",
  emptyStarColor: "#C4C4C4",
  overlayColor: "#607087b3",
  inActiveTab: "#F5F5F5",
  grey4: "#BDBDBD",
  buttonHover: "#b58336",
  onlineGreen: "#6CFF7C",
  offRed: "#ff5050",
  red: "#F65151",
  lightRed: "#FCE9E9",
  lightOrange: "#FBEEDB",
  lightBg: "#F6F8FB",
  hoverPrimary: "#FBF6F0",
  darkGreen: "#219653",
  blue: "#1976D2",
  faintBlue: "#E6F1FC",
  lightGreen: "#DBF9E8",
  skyBlue: "#E4EBF4",
  line: "#E4EBF4",
  offWhite: "#F3F7FC",
  senderBubble: "#8196B2",
  receiverBubble: "#DAE2EC",
  blackishBlue: "#130F26",
  tabBg: "#ECEFF4",
  grey: "#9EA8B7",
  lightSecondaryColor: "#DBF9E8",
  borderGreyColor: "#F0F3F8",
  deepSkyBlue: "#E0EAFF",
  greyBlue: "#213E7A",
  danger: "#F65151",
};
