import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { getCurrentShop } from "./slices/shops";
import type { RootState, AppDispatch } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useCurrentShop() {
  const currentShop = useAppSelector(getCurrentShop);

  return currentShop;
}
