/* eslint-disable indent */
/* eslint-disable func-call-spacing */
import { Disclosure } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebarDetails } from "../Exclusives/sidebarItems";
import { MenuButton, MenuDropdown, MenuNotificationPill } from "./newSideStyles";
import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { hasAnyPermission } from "../../helper/comparisons";
import { sidebarDetailsType } from "../../interfaces/companyConfig.interface";
import { UserPermissions } from "../../app/slices/roles";
import { PlusOrMinusIcon } from "./SIdebarIcons";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { fontSizes } from "../../GlobalStyles/theme";

const NewSidebar: FC<{ handleClick: Function; userPermissions: UserPermissions }> = ({
  handleClick,
  userPermissions,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeDisclosurePanel, setActiveDisclosurePanel] = useState<{
    open: boolean;
    close: () => void;
    key: number;
  } | null>(null);
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      // This is the first render
      firstRenderRef.current = false;
    }
  }, []);

  function togglePanels(newPanel: { open: boolean; close: () => void; key: number }) {
    if (activeDisclosurePanel) {
      if (activeDisclosurePanel.key !== newPanel.key && activeDisclosurePanel.open) {
        activeDisclosurePanel.close();
      }
    }

    setActiveDisclosurePanel({
      ...newPanel,
      open: !newPanel.open,
    });
  }

  return (
    <>
      {sidebarDetails.map(
        ({ name, icon, subRoutes, path, allowedRoles, activeIcon }: sidebarDetailsType, index) => {
          const showNotification = false;
          const shouldAccess = hasAnyPermission(allowedRoles, userPermissions);
          const isActive = pathname === `${path}`;
          const isSubRoute: boolean = !!subRoutes?.some(
            (item) => item.path === pathname
          ) as boolean;

          return (
            <Fragment key={index}>
              {subRoutes && subRoutes.length > 0 ? (
                <Disclosure key={name} defaultOpen={isSubRoute} as="div" className="mx-auto w-full">
                  {(panel) => {
                    const { open, close } = panel;
                    if (
                      isSubRoute &&
                      activeDisclosurePanel?.key !== index &&
                      firstRenderRef.current
                    ) {
                      setActiveDisclosurePanel({ ...panel, key: index });
                    }
                    return (
                      <MenuDropdown active={open}>
                        <Disclosure.Button
                          onClick={() => {
                            if (!open) {
                              close();
                            }
                            togglePanels({ ...panel, key: index });
                          }}
                          style={{ all: "unset", width: "100%" }}
                        >
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            height="2.5rem"
                            width="100%"
                            hoverBg="#291f57"
                            hover
                            bg={open ? "#2E2261" : ""}
                            padding="0.5rem 1rem"
                            borderRadius="0.5rem"
                          >
                            <Flex
                              width="80%"
                              alignItems="center"
                              font-size={fontSizes.small}
                              gap="0.4rem"
                            >
                              <img id="icon" src={open ? activeIcon : icon} alt="" />
                              <Span fontSize={fontSizes.base} className="header-menu">
                                {name}
                              </Span>
                            </Flex>
                            <Flex
                              width="20%"
                              alignItems="center"
                              gap="0.4rem"
                              justifyContent="flex-end"
                            >
                              {showNotification && <MenuNotificationPill>5</MenuNotificationPill>}
                              <PlusOrMinusIcon open={open} />
                            </Flex>
                          </Flex>
                        </Disclosure.Button>
                        {subRoutes && subRoutes.length > 0 && (
                          <Disclosure.Panel className="dropdown">
                            {subRoutes.map((item) => {
                              const comp: string | undefined = item?.path;
                              const currentRoute = pathname === `${comp}`;
                              const shldAccess = hasAnyPermission(
                                item?.allowedRoles,
                                userPermissions
                              );
                              return (
                                <Disclosure.Button key={item.name} style={{ all: "unset" }}>
                                  <MenuButton
                                    isSubroute
                                    active={currentRoute}
                                    height="2rem"
                                    onClick={() => handleClick(item.path as string, shldAccess)}
                                  >
                                    <Flex width="100%" alignItems="center" gap="0.4rem">
                                      <Span fontSize={fontSizes.base}>{item.name}</Span>
                                    </Flex>
                                  </MenuButton>
                                </Disclosure.Button>
                              );
                            })}
                          </Disclosure.Panel>
                        )}
                      </MenuDropdown>
                    );
                  }}
                </Disclosure>
              ) : (
                <MenuButton
                  active={isActive}
                  onClick={() => {
                    handleClick(path as string, shouldAccess);
                  }}
                >
                  <Flex width="70%" alignItems="center" gap="0.4rem">
                    <img id="icon" src={isActive ? activeIcon : icon} alt="" />
                    <Span fontSize={fontSizes.base} className="header-menu">
                      {name}
                    </Span>
                  </Flex>
                  <Flex width="25%" alignItems="center" justifyContent="flex-end">
                    {showNotification && <MenuNotificationPill>5</MenuNotificationPill>}
                  </Flex>
                </MenuButton>
              )}
            </Fragment>
          );
        }
      )}
    </>
  );
};

export default NewSidebar;
