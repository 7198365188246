import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #f4f6f9;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const LeftContainer = styled.div`
  width: 100%;
  padding: 0px;
  height: 100dvh;
  max-height: 100dvh;
  overflow-y: hidden;
  position: relative;
`;

export const PageWrapper = styled.div<{ navbarHeight: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .main-page {
    display: flex;
    width: 100%;
    padding: 1rem 1.5rem 1.8rem 1.5rem;
    gap: 0.5em;
    height: ${({ navbarHeight }) => `calc(100vh - ${navbarHeight! + 10}px)` || "100%"};
    background: transparent;
  }

  @media screen and (max-width: 770px) {
    .main-page {
      padding: 0.5rem;
      height: auto;
      overflow: auto;
    }
  }
`;
