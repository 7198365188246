/* eslint-disable indent */
import { useLazyQuery, useMutation } from "@apollo/client";
import _ from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors, fontSizes } from "../../GlobalStyles/theme";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUserPermissions } from "../../app/slices/roles";
import { toggleSnackbarOpen } from "../../app/slices/snacbar";
import { isLoading } from "../../app/slices/status";
import { getCurrentUser } from "../../app/slices/userInfo";
import FallBackImage from "../../assets/Image.svg";
import cancelIcon from "../../assets/cancel.svg";
import { UserCredentialsAttr } from "../../interfaces/user.interface";
import { CAN_USER_SET_PIN, CREATE_USER_PIN } from "../../schema/auth.schema";
import {
  DefualtManagerRoles,
  defaultCashierRoles,
  defaultSalesRoles,
} from "../../utils/defaultRoles.utils";
import { getItemAsArray, setItem } from "../../utils/localStorage.utils";
import { Button } from "../button/Button";
import { ModalBox } from "../expenseModal/style";
import { InputField } from "../input-field/input";
import { ModalWrapper } from "../modal/ModalWrapper";

interface LockAccountProps {
  closePreLockModal: Function;
  setShowWarn: Function;
}

const LockAccount: FunctionComponent<LockAccountProps> = ({ closePreLockModal, setShowWarn }) => {
  const [pin, setPin] = useState("");
  const [canChangePin, setCanChangePin] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState<string>();

  const { fullName } = useAppSelector((state) => state.user);
  const { session } = useAppSelector((state) => state.session);

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const userRole = useAppSelector(getUserPermissions);
  const { blackishBlue, blackLight, primaryColor } = Colors;

  useEffect(() => {
    const isManager = _.isEqual(userRole.permissions, DefualtManagerRoles.rolePermissions);
    const isCashier = _.isEqual(userRole.permissions, defaultCashierRoles.rolePermissions);
    const isSales = _.isEqual(userRole.permissions, defaultSalesRoles.rolePermissions);
    isManager
      ? setCurrentUserRole("Manager")
      : isSales
      ? setCurrentUserRole("Sales Person")
      : isCashier && setCurrentUserRole("Cashier");
  }, [userRole]);

  const handleCloseModal = () => {
    dispatch(closePreLockModal());
    setShowWarn(false);
  };

  const [getUserPinData] = useLazyQuery<{
    hasSetUserPin: boolean;
  }>(CAN_USER_SET_PIN, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      setCanChangePin(data?.hasSetUserPin);
    },
    onError(error) {
      dispatch(
        toggleSnackbarOpen({
          message: error?.message || error?.graphQLErrors[0]?.message,
          color: "DANGER",
        })
      );
    },
  });

  useEffect(() => {
    getUserPinData();
  }, []);

  const [createUserPin] = useMutation<{ createUserPin: UserCredentialsAttr }>(CREATE_USER_PIN, {
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const handleLock = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!pin) {
      dispatch(toggleSnackbarOpen({ message: "Set your pin first!", color: "INFO" }));
      return;
    }

    try {
      const response = await createUserPin({
        variables: {
          pin,
        },
      });

      if (response.data?.createUserPin.hashedPin) {
        const usersWithPin = getItemAsArray("usersWithPin");
        usersWithPin.push({
          fullName: fullName,
          userId: session?.userId,
          token: session?.token,
        });
        setItem("usersWithPin", usersWithPin);
        dispatch(
          toggleSnackbarOpen({
            message: "Pin Set Successfully",
            color: "SUCCESS",
          })
        );
        dispatch(isLoading(false));
        setShowWarn(false);
      }
    } catch (error: any) {
      dispatch(isLoading(false));
      dispatch(
        toggleSnackbarOpen({ message: error?.message || error?.toString(), color: "DANGER" })
      );
    }
  };

  return (
    <ModalWrapper onClose={() => handleCloseModal()}>
      <ModalBox>
        <h3 style={{ margin: "0px", marginBottom: "1em" }}>
          <button onClick={handleCloseModal}>
            <img src={cancelIcon} alt="" />
          </button>
          <span style={{ fontSize: fontSizes.small }}>
            Are you sure you want to end your shift?
          </span>
        </h3>

        <Flex direction="column" alignItems="center" gap="0.5em" margin="0 0 4em 0">
          <img
            src={
              // (image && URL.createObjectURL(image)) ||
              FallBackImage
            }
            alt=""
            id="user image"
            width="4.375rem"
          />
          <Span fontSize={fontSizes.large} color={blackishBlue}>
            {currentUser.firstName}
          </Span>
          <Span fontSize={fontSizes.small} color={blackLight}>
            {userRole.isShopOwner ? "Shop Owner" : currentUserRole}
          </Span>
        </Flex>
        <InputField
          label="Create pin to continue"
          placeholder="Enter pin to continue"
          type="password"
          noFormat={true}
          backgroundColor="#F4F6F9"
          size="lg"
          color={blackLight}
          borderColor="transparent"
          borderRadius="0.75rem"
          borderSize="0px"
          fontSize={`${fontSizes.base}`}
          width="100%"
          value={pin}
          labelMargin="0 0 1em 0!important"
          onChange={(e) => setPin(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLock(e);
            }
          }}
        />
        <Button
          label="Create Pin"
          onClick={handleLock}
          backgroundColor={primaryColor}
          size="lg"
          color="#fff"
          borderColor="transparent"
          borderRadius="0.75rem"
          borderSize="0px"
          fontSize={`${fontSizes.base}`}
          width="100%"
          margin="1em 0 1em 0"
        />
      </ModalBox>
    </ModalWrapper>
  );
};

export default LockAccount;
