import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPreferences {
  shouldMakeSalesWithoutCashier: boolean;
  sellInFraction: boolean;
  addCustomerToSale: boolean;
  shouldPrintInvoiceKey: boolean;
  makeSalesWithoutCashier: boolean;
  showTotalCustomerDebit: boolean;
}

const initialState: IPreferences = {
  shouldMakeSalesWithoutCashier: false,
  sellInFraction: false,
  addCustomerToSale: false,
  shouldPrintInvoiceKey: false,
  makeSalesWithoutCashier: false,
  showTotalCustomerDebit: false,
};

const shopPreferences = createSlice({
  name: "shopPreferences",
  initialState,
  reducers: {
    toggleMustMakeSaleWithCashier: (state, action: PayloadAction<boolean>) => {
      state.makeSalesWithoutCashier = action.payload;
    },
    toggleShowTotalCustomerDebt: (state, action: PayloadAction<boolean>) => {
      state.showTotalCustomerDebit = action.payload;
    },
    toggleSellInFraction: (state, action: PayloadAction<boolean>) => {
      state.sellInFraction = action.payload;
    },
    toggleAddCustomerToSale: (state, action: PayloadAction<boolean>) => {
      state.addCustomerToSale = action.payload;
    },
    togglePrintPendingCheckout: (state, action: PayloadAction<boolean>) => {
      state.shouldPrintInvoiceKey = action.payload;
    },
  },
});

export const {
  toggleMustMakeSaleWithCashier,
  toggleShowTotalCustomerDebt,
  toggleSellInFraction,
  toggleAddCustomerToSale,
  togglePrintPendingCheckout,
} = shopPreferences.actions;
const shopPreferencesReducer = shopPreferences.reducer;
export default shopPreferencesReducer;
