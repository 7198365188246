import { Company } from "./constants";
import { isDesktop } from "./helper.utils";

export const getCompanyName = (): string => {
  if (isDesktop()) {
    const companyName = process.env.REACT_APP_COMPANY_NAME as string;
    return companyName?.toUpperCase();
  }

  const host = new URL(window.location.href)?.host;

  if (host.includes("figorr")) {
    return Company.FIGORR;
  }

  if (host.includes("edmart")) {
    return Company.EDMART;
  }

  return Company.TIMART;
};
