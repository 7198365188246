import styled from "styled-components";
import { Colors, fontSizes } from "../../GlobalStyles/theme";

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
`;

export const SidebarContainer = styled.div<{ show: boolean }>`
  width: 17rem;
  max-width: 17rem;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  padding: 2rem 0;
  flex-direction: column;
  background-color: ${Colors.primaryColor};
  color: ${Colors.white};
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  z-index: 99999;

  ::-webkit-scrollbar {
    display: none;
  }

  .nav-wrapper {
    height: 82%;
    overflow-y: scroll;
    max-height: 82%;
    padding-bottom: 0.7rem;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .main-nav {
    width: fit-content;
    margin-inline: auto;
    list-style: none;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 95%;
    height: fit-content;
  }

  @media screen and (max-width: 800px) {
    position: fixed;
    transition: 0.3s linear;
    display: ${({ show }) => (show ? "flex" : "none")};
    left: ${({ show }) => (show ? "0px" : "-12.5rem")};
  }
`;

export const MenuButton = styled.div<{ height?: string; active?: boolean; isSubroute?: boolean }>`
  width: 100%;
  position: relative;
  padding-inline: 1rem;
  height: ${({ height }) => height ?? "2.5rem"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ active, isSubroute }) => (active && isSubroute ? Colors.secondaryColor : "white")};
  font-weight: ${({ active }) => (active ? "700" : "400")};
  font-size: ${fontSizes.base};
  background: ${({ active, isSubroute }) => (active && !isSubroute ? "#2E2261" : "")};
  border-radius: 0.5rem;
  cursor: pointer;

  .header-menu {
    font-weight: ${({ active }) => (active ? "700" : "400")};
    color: ${({ active, isSubroute }) => (active && isSubroute ? Colors.secondaryColor : "white")};
    font-size: ${fontSizes.base};
  }

  :hover {
    background-color: #291f57;
    border-radius: 0.5rem;
  }

  #icon {
    width: 17px;
    height: 17px;
  }
`;

export const MenuDropdown = styled.div<{
  active: boolean;
  fontWeight?: string;
  textColor?: string;
}>`
  width: 100%;
  position: relative;
  min-height: ${({ active }) => (active ? "2.5rem" : "2.5rem")};
  max-height: ${({ active }) => (active ? "16rem" : "2.5rem")};
  height: ${({ active }) => (active ? "fit-content" : "2.5rem")};
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? "700" : "400")};
  transition: max-height 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  .header-menu {
    font-weight: ${({ active }) => (active ? "700" : "400")};
    color: white;
    font-size: ${fontSizes.base};
  }

  #icon {
    width: 17px;
    height: 17px;
  }

  .dropdown {
    display: ${({ active }) => (active ? "flex" : "none")};
    height: ${({ active }) => (active ? "100%" : "0")};
    flex-direction: column;
    width: fit-content;
    margin-top: 0;
    list-style: none;
    margin-bottom: 0.5rem;

    margin-inline: 0;
    margin-inline-start: -1rem;
  }

  .divider {
    border-bottom: 1px solid #ffffff4d;
    height: 1px;
    width: 100%;
    margin-inline: auto;
    opacity: 0.3;
    margin-top: 1rem;
  }

  @media screen and (max-width: 800px) {
    align-items: start;
    .dropdown {
      margin-inline: 0;
      margin-inline-start: 1rem;
    }
  }
`;

export const MenuNotificationPill = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 4px;
  background-color: white;
  color: ${Colors.primaryColor};
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

export const UserAccronym = styled.div<{ noBg?: boolean }>`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 20px;
  border-radius: 4px;
  background-color: ${({ noBg }) => (noBg ? "" : "white")};
  color: ${Colors.primaryColor};
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;
