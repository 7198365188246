import CompanyConfig from "../../config";
import { Container } from "./style";

const Loader = ({ noBg }: { noBg?: boolean }) => {
  return (
    <Container noBg={noBg}>
      <img src={CompanyConfig.appLogo} alt="" />
    </Container>
  );
};

export default Loader;
